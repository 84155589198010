import React from "react";
import { connect } from "react-redux";
import { getSession } from "../../config/session";
// import api from "../../config/api";
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";

import {
  getProductCategoriesAllV2,
  addProductCategory,
  uploadPhoto,
  removePhoto,
} from "../../layouts/Admin/actions/ProductCategoryActions";
import {
  getProductById,
  getInventory,
  getProductStock,
  updateReject,
  getCostLogsByProduct,
  getRejectById,
  getPriceLogsByQuery,
  getPriceLogsByProduct,
} from "../../layouts/Admin/actions/InventoryActions";
import { format } from "date-fns";

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Table,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import Select from "react-select";
import queryString from "query-string";

class EditReject extends React.Component {
  constructor(props) {
    const rejectId = props.match.params._id;

    const dateToday = format(new Date(), "MMMM d, yyyy");
    const timeToday = format(new Date(), "h:mm a");

    const defaultPage = JSON.parse(getSession("defaultPage"));
    const userData = JSON.parse(getSession("userData"));
    let userInfo = {};
    if (userData != null) {
      userInfo = userData.info;
    }
    super(props);
    this.state = {
      rejectId: rejectId,
      todayDate: dateToday,
      todayTime: timeToday,
      rejectEntry: {
        products: [],
        remarks: "",
        user: userData && userData.userId ? userData.userId : "",
        totalCost: 0,
      },
      inventoryProducts: [],
      selectedProduct: null,
      addedProduct: null,
      placeId: defaultPage,
      isLoading: false,
      submitted: false,
      isSaving: false,
      addProductModalError: "",
      submittedModal: false,
      modal: false,
      user: {
        name: userInfo.firstName + " " + userInfo.lastName,
      },
    };
  }

  componentDidMount() {
    const userData = JSON.parse(getSession("userData"));

    if (userData == null) {
      this.props.history.push("/login");
      window.location.reload();
    }

    const sessionToken = userData.sessionToken;
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const pageId = pageInfo && pageInfo._id ? pageInfo._id : "";
    let url = this.props.location.search;
    let query = queryString.parse(url);
    let queryStr = "?" + queryString.stringify(query);
    const rejectId = this.state.rejectId;
    this.props.getRejectById(rejectId, sessionToken, (err, res) => {
      if (!err && res) {
        const rejectEntry = {
          products: res.products,
          remarks: res.remarks,
          user: res.user,
          place: res.place,
          createdAt: res.createdAt,
        };

        this.setState(
          {
            rejectEntry: rejectEntry,
            dateToday: res.createdAt,
          },

          this.fetchProductDetails,
          this.initializeCostLost // Calculate costLost after setting initial data
        );
      }
    });
    this.props.getInventory(pageId, queryStr, sessionToken, (err, res) => {
      if (!err && res) {
        if (res.docs && res.docs instanceof Array && res.docs.length > 0) {
          let products = [];
          res.docs.forEach((item) => {
            const productItem = {
              value: item.id,
              label: item.name,
            };
            products.push(productItem);
          });
          this.setState({ inventoryProducts: products });
        }
      }
    });
  }

  showNotification(message) {
    if (message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "success",
        icon: "",
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(notification);
    }
  }

  showNotificationError(message) {
    if (message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "danger",
        icon: "",
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(notification);
    }
  }
  fetchProductDetails = () => {
    const { rejectEntry } = this.state;
    const userData = JSON.parse(getSession("userData"));

    if (!userData) {
      this.props.history.push("/login");
      window.location.reload();
      return;
    }

    const sessionToken = userData.sessionToken;
    // Copy products to avoid mutating state directly
    const updatedProducts = [...rejectEntry.products];

    updatedProducts.forEach((product, index) => {
      const stockQueryStr = `?kind=${product.kind}&variation=${product.variation.id}&volume=${product.volume.id}`;
      const stockQuery = queryString.parse(stockQueryStr);

      const priceQueryStr = `?product=${product.id}&kind=${product.kind}&variation=${product.variation.id}&volume=${product.volume.id}`;
      const priceQuery = queryString.parse(priceQueryStr);

      // Fetch stock count
      this.props.getProductStock(
        product.id,
        stockQuery,
        sessionToken,
        (_, stockRes) => {
          const stockCount =
            stockRes && stockRes.product ? stockRes.stockCount : 0;
          updatedProducts[index] = { ...product, stockCount };

          // If out of stock, show notification
          if (stockCount === 0) {
            this.setState({ outOfStack: true });
            this.showNotificationError(
              "The selected item is out of stock. Please select another volume."
            );
          }

          // Fetch price
          this.props.getPriceLogsByQuery(
            priceQuery,
            sessionToken,
            (_, priceRes) => {
              const price = priceRes && priceRes.price ? priceRes.price : 0;
              const updatedProduct = { ...updatedProducts[index], price };

              // Recalculate `costLost` if `volumeRejected` exists
              const volumeRejected =
                parseFloat(updatedProduct.volumeRejected) || 0;
              const volumeValue =
                updatedProduct.volume && updatedProduct.volume.value
                  ? parseFloat(updatedProduct.volume.value) || 0
                  : 0;

              const costLost =
                price > 0 && volumeRejected > 0 && volumeValue > 0
                  ? (price / volumeValue) * volumeRejected
                  : 0;

              // Update product with `costLost`
              updatedProduct.costLost = costLost;
              updatedProducts[index] = updatedProduct;

              // If price not found, show notification
              if (price === 0) {
                this.setState({ notFoundPrice: true });
                this.showNotificationError(
                  "The selected item price was not found. Please select another variation."
                );
              }

              // Update state after both stock count and price are set
              this.setState({
                rejectEntry: { ...rejectEntry, products: updatedProducts },
              });
            }
          );

          // this.props.getPriceLogsByQuery(
          //   priceQuery,
          //   sessionToken,
          //   (_, priceRes) => {
          //     const price = priceRes && priceRes.price ? priceRes.price : 0;
          //     updatedProducts[index] = { ...updatedProducts[index], price };

          //     // If price not found, show notification
          //     if (price === 0) {
          //       this.setState({ notFoundPrice: true });
          //       this.showNotificationError(
          //         "The selected item price was not found. Please select another variation."
          //       );
          //     }

          //     // Update state after both stock count and price are set
          //     this.setState({
          //       rejectEntry: { ...rejectEntry, products: updatedProducts },
          //     });
          //   }
          // );
        }
      );
    });
  };

  // fetchStockCountsForProducts = () => {
  //   const { rejectEntry } = this.state;
  //   const userData = JSON.parse(getSession("userData"));

  //   if (userData == null) {
  //     this.props.history.push("/login");
  //     window.location.reload();
  //   }

  //   const sessionToken = userData.sessionToken;
  //   // Copy the products array to avoid direct state mutation
  //   const updatedProducts = rejectEntry.products.map((product, index) => {
  //     let queryStr = `?kind=${product.kind}&variation=${product.variation.id}&volume=${product.volume.id}`;
  //     const query = queryString.parse(queryStr);

  //     // Fetch stock count for each product
  //     this.props.getProductStock(product.id, query, sessionToken, (_, res) => {
  //       const stockCount = res && res.product ? res.stockCount : 0;

  //       // Update stockCount for each product in the copied products array
  //       updatedProducts[index] = { ...product, stockCount };

  //       // Update the rejectEntry with the new products array containing stockCounts
  //       this.setState({
  //         rejectEntry: { ...rejectEntry, products: updatedProducts },
  //         outOfStack: stockCount === 0,
  //       });
  //       console.log(stockCount);
  //       if (stockCount === 0) {
  //         this.showNotificationError(
  //           "The selected item is out of stock. Please select another volume."
  //         );
  //       }
  //     });

  //     return product; // Return the product as is temporarily for the map
  //   });
  // };

  // fetchPricesForProducts = () => {
  //   const userData = JSON.parse(getSession("userData"));

  //   if (userData == null) {
  //     this.props.history.push("/login");
  //     window.location.reload();
  //   }

  //   const sessionToken = userData.sessionToken;
  //   const { rejectEntry } = this.state;

  //   // Create a copy of the products array to avoid direct state mutation
  //   const updatedProducts = rejectEntry.products.map((product, index) => {
  //     let queryprice = `?product=${product.id}&kind=${product.kind}&variation=${product.variation.id}&volume=${product.volume.id}`;
  //     const querypricelogs = queryString.parse(queryprice);

  //     // Call getPriceLogsByQuery to fetch the price for each product
  //     this.props.getPriceLogsByQuery(querypricelogs, sessionToken, (_, res) => {
  //       const price = res && res.price ? res.price : 0;

  //       // Update the product's price in the copied products array
  //       updatedProducts[index] = { ...product, price };

  //       // Update the rejectEntry with the new products array containing prices
  //       this.setState({
  //         rejectEntry: { ...rejectEntry, products: updatedProducts },
  //         notFoundPrice: price === 0,
  //       });
  //       console.log("price", price);
  //       if (price === 0) {
  //         this.showNotificationError(
  //           "The selected item price was not found. Please select another variation."
  //         );
  //       }
  //     });

  //     return product; // Return the product as is temporarily for the map
  //   });
  // };

  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    this.setState({
      selectedProduct: null,
      addedProduct: null,
      addProductModalError: "",
    });
  };

  handleInputChange = (inputValue, { action }) => {
    if (action === "input-change") {
      const trimmedValue = inputValue.trim();
      const userData = JSON.parse(getSession("userData"));
      if (userData) {
        const sessionToken = userData.sessionToken;
        const pageInfo = JSON.parse(getSession("pageInfo"));
        const pageId = pageInfo && pageInfo._id ? pageInfo._id : "";
        const query = trimmedValue ? { keyword: trimmedValue } : {};
        this.props.getInventory(pageId, query, sessionToken, (err, res) => {
          if (err) {
            console.error("Error fetching inventory:", err);
          } else if (res && res.docs) {
            const products = res.docs.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            this.setState({ inventoryProducts: products });
          }
        });
      }
    }
  };
  handleSelectProduct = (e) => {
    if (!e) {
      this.setState({ selectedProduct: null });
      return;
    }
    const userData = JSON.parse(getSession("userData"));
    const defaultPage = JSON.parse(getSession("defaultPage"));
    if (userData) {
      const sessionToken = userData.sessionToken;
      const { value: productId } = e;
      this.props.getProductById(productId, sessionToken, (err, res) => {
        if (err) {
          const { response } = err;
          let msg = "";
          if (typeof response === "string") msg = response;
          else {
            if (
              response.data !== null &&
              response.data.message !== null &&
              typeof response.data.message === "string"
            ) {
              msg = response.data.message;
            }
          }
          this.showNotificationError(msg);
        } else {
          const product = res;
          if (e && e.value) {
            let unit = product.unit;
            if (!unit || unit === "")
              unit = product.kind && product.kind === "crops" ? "kg." : "pc.";

            this.setState({
              selectedProduct: product,
              addedProduct: {
                id: product.id,
                name: product.name,
                kind: product.kind,
                place: defaultPage,
                stockCount: 0,
                quantity: 0,
                totalCost: 0,
                costLost: 0,
                reorderPoint: 0,
                volumeRejected: 0,
                volumeRestored: 0,
                price: 0,
                stockWeigh: 0,
                miscCost: 0,
                unit,
                volume: {},
                variation: {},
              },
            });
            if (
              product.volumes &&
              product.volumes.length <= 0 &&
              product.variations &&
              product.variations.length <= 0
            ) {
              let queryprice = `?product=${this.state.addedProduct.id}&kind=${this.state.addedProduct.kind}`;
              const querypricelogs = queryString.parse(queryprice);
              this.props.getPriceLogsByProduct(
                querypricelogs,
                sessionToken,
                (_, res) => {
                  let price = 0;

                  if (res && res.price) {
                    price = res.price;
                    this.setState({
                      addedProduct: {
                        ...this.state.addedProduct,
                        price,
                      },
                      notFoundPrice: false,
                    });
                  } else {
                    this.setState({ notFoundPrice: true });
                    this.showNotificationError(
                      "The selected product was price not found. Please select another product."
                    );
                  }
                }
              );
              let queryStr = `?kind=${this.state.addedProduct.kind}`;
              const query = queryString.parse(queryStr);
              this.props.getProductStock(
                this.state.addedProduct.id,
                query,
                sessionToken,
                (_, res) => {
                  let stockCount = 0;
                  let stockWeigh = 0;
                  if (res && res.product) {
                    stockCount = res.stockCount;
                    stockWeigh = stockCount;
                    this.setState({
                      addedProduct: {
                        ...this.state.addedProduct,
                        stockCount,
                        stockWeigh,
                      },
                      outOfStack: false,
                    });
                  } else {
                    this.setState({ outOfStack: true });
                    this.showNotificationError(
                      "The selected item was already out of stock. Please select another volume "
                    );
                  }
                }
              );
            }
          }
        }
      });
    }
  };
  handleChangeAddProduct = (e) => {
    let { name, value } = e.target;

    if (name === "stockCount") {
      const intValue = !isNaN(parseInt(value)) ? parseInt(value) : 0;

      this.setState({
        addedProduct: {
          ...this.state.addedProduct,
          [name]: intValue,
        },
      });
    } else if (name === "volumeRejected" || name === "volumeRestored") {
      if (value === "" || /^\d*\.?\d*$/.test(value)) {
        this.setState(
          {
            addedProduct: {
              ...this.state.addedProduct,
              [name]: value,
            },
          },
          this.calculateCostLost
        );
      }
    } else {
      this.setState({
        addedProduct: {
          ...this.state.addedProduct,
          [name]: value,
        },
        rejectEntry: {
          ...this.state.rejectEntry,
          [name]: value,
        },
      });
    }
  };
  handleChangeRemarks = (e) => {
    let { name, value } = e.target;

    this.setState({
      rejectEntry: {
        ...this.state.rejectEntry,
        [name]: value,
      },
    });
  };

  calculateCostLost = () => {
    const { price, volumeRejected, kind } = this.state.addedProduct;
    const parsedPrice = parseFloat(price) || 0;
    // const parsedMiscCost = parseFloat(miscCost) || 0;
    const parsedVolumeRejected = parseFloat(volumeRejected) || 0;
    const volumeValue = this.state.addedProduct.volume.value;

    let totalLoss = 0;
    if (parsedPrice > 0 && parsedVolumeRejected > 0) {
      // console.log(this.state.addedProduct.volume.description);
      // totalLoss = (parsedPrice + parsedMiscCost) * parsedVolumeRejected;
      if (kind === "crops") {
        totalLoss = (parsedPrice / volumeValue) * parsedVolumeRejected;
      } else {
        totalLoss = parsedPrice * parsedVolumeRejected;
      }
    }
    this.setState({
      addedProduct: {
        ...this.state.addedProduct,
        costLost: totalLoss,
      },
    });
  };

  handleClickRemoveProduct = (e) => {
    const index = e.currentTarget.dataset.idx;
    const products = this.state.rejectEntry.products;
    products.splice(index, 1);
    let totalCost =
      products && products.length > 0
        ? products.reduce((acc, cur) => acc + cur.totalCost, 0)
        : 0;
    const miscellaneous = this.state.rejectEntry.miscellaneous;
    totalCost +=
      miscellaneous && miscellaneous.length > 0
        ? miscellaneous.reduce((acc, cur) => acc + cur.cost, 0)
        : 0;
    this.setState({
      rejectEntry: {
        ...this.state.rejectEntry,
        products,
        totalCost,
      },
      submitted: false,
    });
  };

  handleSelectVolume = (e) => {
    let { value } = e.target;
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const selectedProduct = this.state.selectedProduct;
    if (selectedProduct !== null && selectedProduct.volumes !== null) {
      let isAdded = false;
      const volume = selectedProduct.volumes.find((i) => i.id === value);
      let reorderPoint = 0;
      let stockCount = 0;
      let stockWeigh = 0;
      if (
        this.state.addedProduct.id &&
        this.state.addedProduct.kind &&
        volume.id
      ) {
        const products = this.state.rejectEntry.products;
        const added = products.find(
          (i) =>
            i.id === this.state.addedProduct.id &&
            i.kind === this.state.addedProduct.kind &&
            (!this.state.addedProduct.variation ||
              i.variation.id === this.state.addedProduct.variation.id) &&
            i.volume.id === volume.id
        );
        if (added) {
          isAdded = true;
          this.showNotificationError(
            "The selected item was already added. Please select another volume"
          );
        } else {
          let queryStr = `?kind=${this.state.addedProduct.kind}&volume=${volume.id}`;
          if (this.state.addedProduct.variation) {
            queryStr += `&variation=${this.state.addedProduct.variation.id}`;
          }
          const query = queryString.parse(queryStr);
          this.props.getProductStock(
            this.state.addedProduct.id,
            query,
            sessionToken,
            (_, res) => {
              if (res && res.product) {
                stockCount = res.stockCount;
                reorderPoint = res.product.reorderPoint;

                stockWeigh = stockCount;
                this.setState({
                  addedProduct: {
                    ...this.state.addedProduct,
                    reorderPoint,
                    stockCount,
                    stockWeigh,
                  },
                  outOfStack: false,
                });
              } else {
                this.setState({ outOfStack: true });
                this.showNotificationError(
                  "The selected item was already out of stock. Please select another volume "
                );
                stockCount = 0;
                this.setState({
                  addedProduct: {
                    ...this.state.addedProduct,
                    reorderPoint,
                    stockCount,
                  },
                });
              }
            }
          );

          this.props.getCostLogsByProduct(
            this.state.addedProduct.id,
            query,
            sessionToken,
            (_, res) => {
              if (res) {
                let miscCost = 0;
                const data = res.find(
                  (log) =>
                    log.product.id === this.state.addedProduct.id &&
                    log.product.kind === this.state.addedProduct.kind &&
                    (!this.state.addedProduct.variation ||
                      log.product.variation.id ===
                        this.state.addedProduct.variation.id) &&
                    log.product.volume.id === this.state.addedProduct.volume.id
                );

                if (data) {
                  miscCost = data.miscCost;
                }

                this.setState({
                  addedProduct: {
                    ...this.state.addedProduct,
                    miscCost,
                  },
                });
              }
            }
          );
          let queryprice = `?product=${this.state.addedProduct.id}&kind=${this.state.addedProduct.kind}&volume=${volume.id}`;
          if (this.state.addedProduct.variation) {
            queryprice += `&variation=${this.state.addedProduct.variation.id}`;
          }
          const querypricelogs = queryString.parse(queryprice);
          this.props.getPriceLogsByQuery(
            querypricelogs,
            sessionToken,
            (_, res) => {
              let price = 0;
              if (res && res.price) {
                price = res.price;
                this.setState({
                  addedProduct: {
                    ...this.state.addedProduct,
                    price,
                  },
                  notFoundPrice: false,
                });
              } else {
                this.setState({ notFoundPrice: true });
                this.showNotificationError(
                  "The selected item was price not found. Please select another volume "
                );
              }
            }
          );
        }
      }
      if (!isAdded) {
        this.setState({
          addedProduct: {
            ...this.state.addedProduct,
            volume,
          },
        });
      }
    }
  };
  handleSelectVariation = (e) => {
    let { value } = e.target;
    const selectedProduct = this.state.selectedProduct;
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    if (selectedProduct !== null && selectedProduct.variations !== null) {
      let isAdded = false;
      const variation = selectedProduct.variations.find((i) => i.id === value);
      let reorderPoint = 0;
      let stockCount = 0;
      let volume = 0;
      let stockWeigh = 0;
      if (
        this.state.addedProduct.id &&
        this.state.addedProduct.kind &&
        variation.id
        // this.state.addedProduct.volume.id
      ) {
        const products = this.state.rejectEntry.products;
        const added = products.find(
          (i) =>
            i.id === this.state.addedProduct.id &&
            i.kind === this.state.addedProduct.kind &&
            i.variation.id === variation.id &&
            i.volume.id === this.state.addedProduct.volume.id
        );
        if (added) {
          isAdded = true;
          this.showNotificationError(
            "The selected item was already added. Please select another variation"
          );
        } else {
          let queryStr = `?kind=${this.state.addedProduct.kind}&variation=${variation.id}&volume=${this.state.addedProduct.volume.id}`;
          const query = queryString.parse(queryStr);
          this.props.getProductStock(
            this.state.addedProduct.id,
            query,
            sessionToken,
            (_, res) => {
              if (res && res.product) {
                if (res.stockCount !== undefined && res.stockCount !== null) {
                  stockCount = res.stockCount;

                  if (res.product.reorderPoint !== undefined) {
                    reorderPoint = res.product.reorderPoint;
                  } else {
                    reorderPoint = 0;
                  }

                  if (res.volume && res.volume.value !== undefined) {
                    volume = res.volume.value;
                  } else {
                    volume = 0;
                  }

                  stockWeigh = stockCount;
                  this.setState({
                    addedProduct: {
                      ...this.state.addedProduct,
                      reorderPoint,
                      stockCount,
                      outOfStack: false,
                      stockWeigh,
                    },
                  });
                } else {
                  this.setState({ outOfStack: true });

                  this.showNotificationError(
                    "The selected item is out of stock. Please select another variation."
                  );
                  stockCount = 0;
                  this.setState({
                    addedProduct: {
                      ...this.state.addedProduct,
                      reorderPoint,
                      stockCount,
                    },
                  });
                }
              } else {
                this.setState({ outOfStack: true });

                this.showNotificationError(
                  "The selected item is out of stock. Please select another variation."
                );
                stockCount = 0;
                this.setState({
                  addedProduct: {
                    ...this.state.addedProduct,
                    reorderPoint,
                    stockCount,
                  },
                });
              }
            }
          );
          this.props.getCostLogsByProduct(
            this.state.addedProduct.id,
            query,
            sessionToken,
            (_, res) => {
              if (res) {
                let miscCost = 0;
                const data = res.find(
                  (log) =>
                    log.product.id === this.state.addedProduct.id &&
                    log.product.kind === this.state.addedProduct.kind &&
                    log.product.variation.id ===
                      this.state.addedProduct.variation.id &&
                    log.product.volume.id === this.state.addedProduct.volume.id
                );

                if (data) {
                  miscCost = data.miscCost;
                }

                this.setState({
                  addedProduct: {
                    ...this.state.addedProduct,
                    miscCost,
                  },
                });
              }
            }
          );
          let queryprice = `?product=${this.state.addedProduct.id}&kind=${this.state.addedProduct.kind}&variation=${variation.id}&volume=${this.state.addedProduct.volume.id}`;
          const querypricelogs = queryString.parse(queryprice);
          this.props.getPriceLogsByQuery(
            querypricelogs,
            sessionToken,
            (_, res) => {
              let price = 0;
              if (res && res.price) {
                price = res.price;
                this.setState({
                  addedProduct: {
                    ...this.state.addedProduct,
                    price,
                  },
                  notFoundPrice: false,
                });
              } else {
                this.setState({ notFoundPrice: true });
                this.showNotificationError(
                  "The selected item was price not found. Please select another variation "
                );
              }
            }
          );
        }
      }
      if (!isAdded) {
        this.setState({
          addedProduct: {
            ...this.state.addedProduct,
            variation,
          },
        });
      }
    }
  };

  initializeCostLost = () => {
    const updatedProducts = this.state.rejectEntry.products.map((product) => {
      const parsedPrice = parseFloat(product.price) || 0;
      const volumeValue =
        product.volume && product.volume.value
          ? parseFloat(product.volume.value) || 0
          : 0;
      const parsedVolumeRejected = parseFloat(product.volumeRejected) || 0;

      const costLost =
        parsedPrice > 0 && parsedVolumeRejected > 0 && volumeValue > 0
          ? (parsedPrice / volumeValue) * parsedVolumeRejected
          : 0;

      return {
        ...product,
        costLost, // Ensure costLost is initialized
      };
    });

    this.setState({
      rejectEntry: {
        ...this.state.rejectEntry,
        products: updatedProducts,
      },
    });
  };

  handleChangeProduct = (e) => {
    const { name, value } = e.target;
    const index = e.currentTarget.dataset.idx;
    const products = [...this.state.rejectEntry.products]; // Copy products to avoid direct state mutation

    if (name === "volumeRejected" || name === "volumeRestored") {
      // Allow only valid numbers (including decimals)
      if (value === "" || /^\d*\.?\d*$/.test(value)) {
        // Update the input as-is for display purposes
        products[index][name] = value;

        // Parse the value for calculation only if it's valid
        const parsedValue = parseFloat(value) || 0;

        if (name === "volumeRejected") {
          // Recalculate costLost
          const parsedPrice = parseFloat(products[index].price) || 0;
          const volumeValue =
            products[index].volume && products[index].volume.value
              ? parseFloat(products[index].volume.value) || 0
              : 0;

          const costLost =
            parsedPrice > 0 && parsedValue > 0 && volumeValue > 0
              ? (parsedPrice / volumeValue) * parsedValue
              : 0;

          products[index].costLost = costLost;
        }
      }
    } else {
      // For other fields, update value directly
      products[index][name] = value;
    }

    // Update state
    this.setState({
      rejectEntry: {
        ...this.state.rejectEntry,
        products,
      },
    });
  };

  // handleChangeProduct = (e) => {
  //   const { name, value } = e.target;
  //   const index = e.currentTarget.dataset.idx;
  //   const products = [...this.state.rejectEntry.products]; // Copy to avoid direct state mutation
  //   const addedProduct = { ...this.state.addedProduct };

  //   if (name === "volumeRejected" || name === "volumeRestored") {
  //     if (value === "" || /^\d*\.?\d*$/.test(value)) {
  //       // Update the input value (store as string temporarily)
  //       products[index][name] = value;
  //       addedProduct[name] = value;

  //       // Trigger recalculation for `costLost` if volumeRejected is updated
  //       if (name === "volumeRejected") {
  //         const parsedPrice = parseFloat(products[index].price) || 0; // Fetch price directly from product
  //         const volumeValue =
  //           addedProduct.volume && addedProduct.volume.value
  //             ? parseFloat(addedProduct.volume.value) || 0
  //             : 0;
  //         const parsedVolumeRejected = parseFloat(value) || 0;

  //         // Calculate `costLost`
  //         const costLost =
  //           parsedPrice > 0 && parsedVolumeRejected > 0 && volumeValue > 0
  //             ? (parsedPrice / volumeValue) * parsedVolumeRejected
  //             : 0;

  //         // Update `costLost` in product and addedProduct
  //         addedProduct.costLost = costLost;
  //         products[index].costLost = costLost;
  //       }

  //       // Update state with updated products and addedProduct
  //       this.setState({
  //         rejectEntry: {
  //           ...this.state.rejectEntry,
  //           products,
  //         },
  //         addedProduct,
  //       });
  //     }
  //   } else {
  //     // For other fields
  //     products[index][name] = value;
  //     this.setState({
  //       rejectEntry: {
  //         ...this.state.rejectEntry,
  //         products,
  //       },
  //     });
  //   }
  // };

  // handleChangeProduct = (e) => {
  //   const { name, value } = e.target;
  //   const index = e.currentTarget.dataset.idx;
  //   const products = [...this.state.rejectEntry.products]; // Copy to avoid direct state mutation
  //   const addedProduct = { ...this.state.addedProduct };

  //   if (name === "volumeRejected" || name === "volumeRestored") {
  //     if (value === "" || /^\d*\.?\d*$/.test(value)) {
  //       // Parse as a float to ensure correct type
  //       const parsedValue = parseFloat(value) || 0;

  //       // Update `volumeRejected` or `volumeRestored` in `products` and `addedProduct`
  //       products[index][name] = parsedValue;
  //       addedProduct[name] = parsedValue;
  //       // Calculate `costLost`
  //       if (name === "volumeRejected") {
  //         const parsedPrice = parseFloat(addedProduct.price) || 0;
  //         const volumeValue = parseFloat(addedProduct.volume.value) || 0;
  //         const parsedVolumeRejected = parseFloat(value) || 0;
  //         // Calculate `lossCost` using only `volumeRejected`
  //         const costLost =
  //           parsedPrice > 0 && parsedVolumeRejected > 0 && volumeValue > 0
  //             ? (parsedPrice / volumeValue) * parsedVolumeRejected
  //             : 0;

  //         // Update `costLost` in `addedProduct` and `products`
  //         addedProduct.costLost = costLost;
  //         products[index].costLost = costLost;
  //       }

  //       // Set state with updated products and addedProduct
  //       this.setState({
  //         rejectEntry: {
  //           ...this.state.rejectEntry,
  //           products,
  //         },
  //         addedProduct,
  //       });
  //     }
  //   } else {
  //     // For other fields, simply update the product and set state
  //     products[index][name] = value;
  //     this.setState({
  //       rejectEntry: {
  //         ...this.state.rejectEntry,
  //         products,
  //       },
  //     });
  //   }
  // };

  renderVolumes() {
    if (
      this.state.selectedProduct !== null &&
      this.state.selectedProduct.volumes !== null
    ) {
      const volumes = this.state.selectedProduct.volumes;
      return volumes.map((item, index) => (
        <option key={index} value={item.id}>
          {item.description}
        </option>
      ));
    }
  }
  renderVariations() {
    if (
      this.state.selectedProduct !== null &&
      this.state.selectedProduct.variations !== null
    ) {
      const variations = this.state.selectedProduct.variations;
      return variations.map((item, index) => (
        <option key={index} value={item.id}>
          {item.description}
        </option>
      ));
    }
  }
  renderSizes() {
    if (
      this.state.selectedProduct !== null &&
      this.state.selectedProduct.sizes !== null
    ) {
      const variations = this.state.selectedProduct.sizes;
      return variations.map((item, index) => (
        <option key={index} value={item.description}>
          {item.description}
        </option>
      ));
    }
  }
  handleAddProduct = () => {
    const products = this.state.rejectEntry.products;
    const addedProduct = this.state.addedProduct;
    const isStockError = this.state.outOfStack;
    const isPriceUnavailable = this.state.notFoundPrice;
    if (addedProduct && addedProduct.id) {
      if (
        // !addedProduct.totalCost ||
        // parseFloat(addedProduct.totalCost <= 0) ||
        // !addedProduct.quantity ||
        // isNaN(addedProduct.quantity) ||
        // addedProduct.quantity <= 0 ||
        // !addedProduct.volume ||
        // !addedProduct.variation ||
        !addedProduct.volumeRejected ||
        parseFloat(addedProduct.volumeRejected <= 0)
      ) {
        this.setState({
          submittedModal: true,
          addProductModalError: "Some field are missing or have invalid values",
        });
      } else if (isStockError === true || isPriceUnavailable === true) {
        this.setState({
          submittedModal: true,
        });
        this.showNotificationError(
          "The selected item was already out of stock or price not found. Please select another item "
        );
      } else {
        addedProduct.totalCost = parseFloat(addedProduct.totalCost);
        addedProduct.volumeRejected =
          parseFloat(addedProduct.volumeRejected) || 0;
        addedProduct.volumeRestored =
          parseFloat(addedProduct.volumeRestored) || 0;
        products.push(addedProduct);
        let totalCost =
          products && products.length > 0
            ? products.reduce((acc, cur) => acc + cur.totalCost, 0)
            : 0;
        const miscellaneous = this.state.rejectEntry.miscellaneous;
        totalCost +=
          miscellaneous && miscellaneous.length > 0
            ? miscellaneous.reduce((acc, cur) => acc + cur.cost, 0)
            : 0;
        this.setState({
          rejectEntry: {
            ...this.state.rejectEntry,
            products,
            totalCost,
          },
          modal: false,
          submittedModal: false,
          addProductModalError: "",
        });
      }
    } else {
      this.setState({
        submittedModal: true,
        addProductModalError: "Please select a product",
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));

    if (userData) {
      const sessionToken = userData.sessionToken;
      const reject = { ...this.state.rejectEntry };
      const rejectId = this.state.rejectId;
      let hasError = false;
      if (reject) {
        this.setState({ submitted: true });
        if (!reject.remarks) {
          hasError = true;
          this.showNotificationError(
            "Some fields are required. Please fill in the required fields"
          );
        }
        if (!hasError) {
          if (!window.confirm("Do you want to save this item?")) {
            return false;
          }
          this.setState({ isSaving: true });
          // if (!supplier.user) reject.user = userData.userId;
          if (reject.products != null)
            reject.products.forEach((item) => {
              item.volumeRejected = parseFloat(item.volumeRejected || 0); // Parse each value or default to 0
              item.volumeRestored = parseFloat(item.volumeRestored || 0); // Parse each value or default to 0
            });
          this.props.updateReject(
            rejectId,
            reject,
            sessionToken,
            (err, res) => {
              if (res) {
                this.setState({ submitted: false, isSaving: false });
                if (res && res.status === "success") {
                  this.showNotification("Reject Product has been updated");
                  setTimeout(() => {
                    this.props.history.push("/inventory-rejects");
                  }, 3000);
                }
              } else {
                if (err) {
                  this.setState({ submitted: false, isSaving: false });
                  const { response } = err;
                  let msg = "";
                  if (typeof response === "string") msg = response;
                  else {
                    if (
                      response.data !== null &&
                      response.data.message !== null &&
                      typeof response.data.message === "string"
                    ) {
                      msg = response.data.message;
                    }
                  }
                  this.setState({ addSupplierModalError: msg });
                } else {
                  this.setState({
                    submitted: false,
                    isSaving: false,
                    addSupplierModalError:
                      "An unknown error occured. Please try again.",
                  });
                }
              }
            }
          );
        }
      } else {
        this.setState({ submitted: true });
        this.showNotificationError(
          "Some fields are required. Please fill in the required fields"
        );
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  };
  onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  renderProductCategoriesMain() {
    if (
      this.state.productCategoriesMain !== undefined ||
      this.state.productCategoriesMain != null
    ) {
      const productCategoriesMain = this.state.productCategoriesMain;
      return productCategoriesMain.map((item, index) => (
        <option key={index} value={item._id}>
          {item.name}
        </option>
      ));
    }
  }
  handleChangeEndDate = (date) => {
    this.setState({
      dateEnd: date,
    });
  };

  handleChangeStartDate = (date) => {
    this.setState({
      dateStart: date,
    });
  };

  renderRejectForm(rejectEntry) {
    return (
      <>
        {" "}
        <Row>
          <Col sm="12">
            <Row>
              <Col lg="12" md="12" sm="12">
                <FormGroup>
                  <Alert color="primary">Edit reject details.</Alert>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="3" sm="6">
                <FormGroup>
                  <label htmlFor="slug" className="control-label">
                    Date Created
                    <em className="text-muted"></em>
                  </label>
                  <Input
                    readOnly
                    name="createdDate"
                    className="createdDate"
                    placeholder="Date Created"
                    type="text"
                    defaultValue={this.state.todayDate}
                  />
                </FormGroup>
              </Col>
              <Col lg="3" md="3" sm="6">
                <FormGroup
                  className={
                    this.state.submitted && !this.state.rejectEntry.remarks
                      ? " has-danger"
                      : ""
                  }>
                  <Label htmlFor="remarks" className="control-label">
                    Description <em className="text-muted">(Required)</em>
                  </Label>
                  <Input
                    id="remarks"
                    name="remarks"
                    placeholder="Description"
                    type="text"
                    value={
                      this.state.rejectEntry && this.state.rejectEntry.remarks
                        ? this.state.rejectEntry.remarks
                        : ""
                    }
                    onChange={this.handleChangeRemarks}
                  />
                </FormGroup>
              </Col>
              <Col lg="3" md="3" sm="6">
                <FormGroup>
                  <label htmlFor="slug" className="control-label">
                    Time of Entry
                    <em className="text-muted"></em>
                  </label>
                  <Input
                    readOnly
                    name="time"
                    className="time"
                    placeholder="Time"
                    type="text"
                    defaultValue={this.state.todayTime}
                  />
                </FormGroup>
              </Col>
              <Col lg="3" md="3" sm="6">
                <FormGroup>
                  <label htmlFor="slug" className="control-label">
                    Prepared By
                    <em className="text-muted"></em>
                  </label>
                  <Input
                    readOnly
                    name="time"
                    className="time"
                    placeholder="Time"
                    type="text"
                    defaultValue={this.state.user.name}
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* <Row>
              <Col sm="12">
                <FormGroup>
                  <Label htmlFor="remarks" className="control-label">
                    Description
                  </Label>
                  <Input
                    id="remarks"
                    name="remarks"
                    placeholder="Description"
                    type="textarea"
                    value={
                      this.state.rejectEntry && this.state.rejectEntry.remarks
                        ? this.state.rejectEntry.remarks
                        : ""
                    }
                    onChange={this.handleChangeRemarks}
                    style={{ height: "100px" }}
                  />
                </FormGroup>
              </Col>
            </Row> */}

            <Row>
              <Col>
                <FormGroup>
                  <p className="control-label">Product</p>
                  <Row>
                    <Col>
                      <Table
                        style={{ minWidth: "600px" }}
                        className="tablesorter table-hover"
                        responsive
                        size="sm">
                        <thead className="text-primary">
                          <tr>
                            <th>Product</th>
                            <th>Variation</th>
                            <th>Volume</th>

                            <th>Stock Weigh</th>
                            <th>Price</th>
                            <th>Rejected Volume</th>
                            <th>Restored Volume</th>
                            {/* <th>Quantity</th> */}
                            <th>Loss Cost</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rejectEntry.products.length > 0 ? (
                            rejectEntry.products.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <FormGroup
                                      className={
                                        this.state.submitted && !item.name
                                          ? " has-danger"
                                          : ""
                                      }>
                                      <Input
                                        name="name"
                                        readOnly
                                        className="name"
                                        placeholder="Item Name"
                                        type="text"
                                        defaultValue={item.name}
                                        data-idx={i}
                                        onChange={this.handleChangeProduct}
                                      />
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <FormGroup
                                      className={
                                        this.state.submitted &&
                                        !item.variation &&
                                        !item.variation.description
                                          ? " has-danger"
                                          : ""
                                      }>
                                      <Input
                                        name="variation"
                                        readOnly
                                        className="variation"
                                        placeholder="Variation"
                                        type="text"
                                        defaultValue={
                                          item.variation.description
                                        }
                                        data-idx={i}
                                      />
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <FormGroup
                                      className={
                                        this.state.submitted &&
                                        !item.volume &&
                                        !item.volume.description
                                          ? " has-danger"
                                          : ""
                                      }>
                                      <Input
                                        name="volume"
                                        readOnly
                                        className="volume"
                                        placeholder="Volume"
                                        type="text"
                                        defaultValue={item.volume.description}
                                        data-idx={i}
                                      />
                                    </FormGroup>
                                  </td>

                                  {/* <td>
                                    <FormGroup
                                      className={
                                        this.state.submitted &&
                                        (!item.stockCount ||
                                          isNaN(item.stockCount))
                                          ? " has-danger"
                                          : ""
                                      }>
                                      <Input
                                        name="stockCount"
                                        className="stockCount"
                                        placeholder="Stock Count"
                                        readOnly
                                        type="text"
                                        value={
                                          item.stockCount ? item.stockCount : 0
                                        }
                                        data-idx={i}
                                        onChange={this.handleChangeProduct}
                                      />
                                    </FormGroup>
                                  </td> */}
                                  <td>
                                    <FormGroup>
                                      <Input
                                        name="stockWeigh"
                                        className="stockWeigh"
                                        placeholder="Stock Weigh"
                                        readOnly
                                        type="text"
                                        value={
                                          item.stockWeigh ? item.stockWeigh : ""
                                        }
                                        data-idx={i}
                                      />
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <FormGroup
                                      className={
                                        this.state.submitted &&
                                        (!item.price || isNaN(item.price))
                                          ? " has-danger"
                                          : ""
                                      }>
                                      <InputGroup>
                                        <InputGroupText
                                          className={
                                            this.state.submitted &&
                                            (!item.price || isNaN(item.price))
                                              ? "has-danger"
                                              : ""
                                          }>
                                          &#8369;
                                        </InputGroupText>
                                        <Input
                                          name="price"
                                          className="price"
                                          placeholder="Price"
                                          readOnly
                                          type="text"
                                          value={item.price ? item.price : 0}
                                          data-idx={i}
                                          onChange={this.handleChangeProduct}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <FormGroup
                                      className={
                                        this.state.submitted &&
                                        (!item.volumeRejected ||
                                          isNaN(item.volumeRejected))
                                          ? " has-danger"
                                          : ""
                                      }>
                                      <Input
                                        name="volumeRejected"
                                        className="volumeRejected"
                                        placeholder="Rejected Volume"
                                        type="text"
                                        value={
                                          item.volumeRejected
                                            ? item.volumeRejected
                                            : "0"
                                        }
                                        data-idx={i}
                                        onChange={this.handleChangeProduct}
                                      />
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <FormGroup
                                      className={
                                        this.state.submitted &&
                                        (!item.volumeRestored ||
                                          isNaN(item.volumeRestored))
                                          ? " has-danger"
                                          : ""
                                      }>
                                      <Input
                                        name="volumeRestored"
                                        className="volumeRestored"
                                        placeholder="Restored Volume"
                                        type="text"
                                        value={
                                          item.volumeRestored
                                            ? item.volumeRestored
                                            : "0"
                                        }
                                        data-idx={i}
                                        onChange={this.handleChangeProduct}
                                      />
                                    </FormGroup>
                                  </td>

                                  <td>
                                    <FormGroup
                                      className={
                                        this.state.submitted &&
                                        (!item.costLost || isNaN(item.costLost))
                                          ? " has-danger"
                                          : ""
                                      }>
                                      <InputGroup>
                                        <InputGroupText
                                          className={
                                            this.state.submitted &&
                                            (!item.costLost ||
                                              isNaN(item.costLost))
                                              ? "has-danger"
                                              : ""
                                          }>
                                          &#8369;
                                        </InputGroupText>
                                        <Input
                                          name="costLost"
                                          readOnly
                                          className="costLost"
                                          placeholder="Loss Cost"
                                          type="text"
                                          value={
                                            item.costLost ? item.costLost : 0
                                          }
                                          data-idx={i}
                                          onChange={this.handleChangeProduct}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <Button
                                      alt="Remove"
                                      title="Remove"
                                      style={{ marginRight: "10px" }}
                                      className="btn-round btn-sm"
                                      color="danger"
                                      type="button"
                                      data-idx={i}
                                      onClick={this.handleClickRemoveProduct}>
                                      <Fa icon="times" />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <>
                              <tr>
                                <td colSpan={9}>
                                  <h5 className="text-danger">
                                    <em>No products added.</em>
                                  </h5>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Button
                    alt="Add"
                    title="Add"
                    className="btn-round btn-sm"
                    color="info"
                    type="button"
                    onClick={this.toggleModal}>
                    <Fa icon="plus" />
                    &nbsp; Add Item
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
  render() {
    const pageInfo = JSON.parse(getSession("pageInfo"));
    // let { submitted } = this.state;

    if (pageInfo && pageInfo._id) {
      return (
        <>
          <div className="content">
            <div className="react-notification-alert-container">
              <NotificationAlert ref="notify" />
            </div>
            <Row>
              <Col sm="12" md="12" lg="12">
                <Card>
                  <Form
                    onSubmit={this.handleSubmit}
                    onKeyPress={this.onKeyPress}>
                    <CardHeader>
                      <h4 className="title">Edit Reject</h4>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          {this.renderRejectForm(this.state.rejectEntry)}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="pull-right">
                      <Button className="btn-round" color="info" type="submit">
                        Save
                      </Button>
                      <Link
                        to="/inventory-rejects"
                        className="btn btn-round btn-light">
                        Cancel
                      </Link>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
            <LoadingOverlay
              active={this.state.isSaving}
              spinner
              text="Saving..."></LoadingOverlay>

            <div className="modal-section">
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggleModal}
                backdrop="static"
                className={this.props.className}
                size="lg">
                <div className="modal-header">
                  <h4 className="modal-title">Add Product</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={this.toggleModal}
                    aria-label="Close"
                    style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <ModalBody>
                  <form action="">
                    <Row>
                      <Col sm="12" md="6" lg="6">
                        <FormGroup
                          className={
                            this.state.submittedModal &&
                            (!this.state.addedProduct ||
                              !this.state.addedProduct.id)
                              ? "has-danger"
                              : ""
                          }>
                          {this.state.addProductModalError && (
                            <Alert color="danger">
                              {this.state.addProductModalError}
                            </Alert>
                          )}
                          <Select
                            className="react-select"
                            options={this.state.inventoryProducts}
                            onChange={this.handleSelectProduct}
                            onInputChange={this.handleInputChange}
                            placeholder="Select a product"
                            value={this.state.inventoryProducts.find(
                              (item) =>
                                item.value ===
                                (this.state.selectedProduct &&
                                this.state.selectedProduct.id
                                  ? this.state.selectedProduct.id
                                  : "")
                            )}
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="6">
                        <FormGroup>
                          <p className="control-label">
                            Kind:&nbsp;
                            {this.state.addedProduct &&
                            this.state.addedProduct.kind
                              ? capitalizeFirstLetter(
                                  this.state.addedProduct.kind
                                )
                              : "Goods"}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.selectedProduct &&
                        this.state.selectedProduct.variations &&
                        this.state.selectedProduct.variations.length > 0 && (
                          <>
                            <Col sm="12" md="6" lg="6">
                              <FormGroup
                                className={
                                  this.state.submittedModal &&
                                  (!this.state.addedProduct ||
                                    !this.state.addedProduct.variation ||
                                    !this.state.addedProduct.variation.id)
                                    ? " has-danger"
                                    : ""
                                }>
                                <Label
                                  htmlFor="variation"
                                  className="control-label">
                                  Variation
                                </Label>
                                <Input
                                  id="variation"
                                  name="variation"
                                  className="variation"
                                  placeholder="Variation"
                                  type="select"
                                  value={
                                    this.state.addedProduct &&
                                    this.state.addedProduct.variation &&
                                    this.state.addedProduct.variation.id
                                      ? this.state.addedProduct.variation.id
                                      : ""
                                  }
                                  onChange={this.handleSelectVariation}>
                                  <option value="">Select one</option>
                                  {this.renderVariations()}
                                </Input>
                              </FormGroup>
                            </Col>
                          </>
                        )}
                      {this.state.selectedProduct &&
                        this.state.selectedProduct.volumes &&
                        this.state.selectedProduct.volumes.length > 0 && (
                          <>
                            <Col sm="12" md="6" lg="6">
                              <FormGroup
                                className={
                                  this.state.submittedModal &&
                                  (!this.state.addedProduct ||
                                    !this.state.addedProduct.volume ||
                                    !this.state.addedProduct.volume.id)
                                    ? " has-danger"
                                    : ""
                                }>
                                <Label
                                  htmlFor="volume"
                                  className="control-label">
                                  Volume
                                </Label>
                                <Input
                                  id="volume"
                                  name="volume"
                                  className="volume"
                                  placeholder="Volume"
                                  type="select"
                                  value={
                                    this.state.addedProduct &&
                                    this.state.addedProduct.volume &&
                                    this.state.addedProduct.volume.id
                                      ? this.state.addedProduct.volume.id
                                      : ""
                                  }
                                  onChange={this.handleSelectVolume}>
                                  <option value="">Select one</option>
                                  {this.renderVolumes()}
                                </Input>
                              </FormGroup>
                            </Col>
                          </>
                        )}
                      {/* <Col sm="12" md="6" lg="6">
                        <FormGroup
                          className={
                            this.state.submittedModal &&
                            (!this.state.addedProduct ||
                              !this.state.addedProduct.stockCount ||
                              isNaN(this.state.addedProduct.stockCount))
                              ? " has-danger"
                              : ""
                          }>
                          <Label htmlFor="stockCount" className="control-label">
                            Stock Count
                          </Label>
                          <InputGroup>
                            <Input
                              id="stockCount"
                              readOnly
                              name="stockCount"
                              className="stockCount"
                              placeholder="Stock Count"
                              type="text"
                              value={
                                this.state.addedProduct &&
                                this.state.addedProduct.stockCount
                                  ? this.state.addedProduct.stockCount
                                  : 0
                              }
                              onChange={this.handleChangeAddProduct}
                            />
                            <InputGroupText
                              className={
                                this.state.submittedModal &&
                                (!this.state.addedProduct ||
                                  !this.state.addedProduct.stockCount ||
                                  isNaN(this.state.addedProduct.stockCount))
                                  ? " has-danger"
                                  : ""
                              }>
                              {this.state.addedProduct &&
                              this.state.addedProduct.unit
                                ? this.state.addedProduct.unit
                                : "pc(s)."}
                            </InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col> */}
                      {this.state.addedProduct && (
                        <Col sm="12" md="6" lg="6">
                          <FormGroup>
                            <Label
                              htmlFor="stockWeigh"
                              className="control-label">
                              {this.state.addedProduct.kind === "crops"
                                ? "Stock Weigh"
                                : "Available Quantity"}
                            </Label>

                            <Input
                              id="stockWeigh"
                              readOnly
                              name="stockCount"
                              className="stockWeigh"
                              placeholder="Stock Weigh"
                              type="text"
                              value={
                                this.state.addedProduct &&
                                this.state.addedProduct.stockWeigh
                                  ? this.state.addedProduct.stockWeigh
                                  : 0
                              }
                              onChange={this.handleChangeAddProduct}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      <Col sm="12" md="6" lg="6">
                        <FormGroup
                          className={
                            this.state.submittedModal &&
                            (!this.state.addedProduct ||
                              !this.state.addedProduct.price ||
                              isNaN(this.state.addedProduct.price))
                              ? " has-danger"
                              : ""
                          }>
                          <Label htmlFor="price" className="control-label">
                            Price
                          </Label>
                          <InputGroup>
                            <InputGroupText>&#8369;</InputGroupText>
                            <Input
                              id="price"
                              name="price"
                              readOnly
                              className="price"
                              placeholder="price"
                              type="text"
                              value={
                                this.state.addedProduct &&
                                this.state.addedProduct.price
                                  ? this.state.addedProduct.price
                                  : 0
                              }
                              onChange={this.handleChangeAddProduct}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="6">
                        <FormGroup
                          className={
                            this.state.submittedModal &&
                            (!this.state.addedProduct ||
                              !this.state.addedProduct.volumeRejected ||
                              isNaN(this.state.addedProduct.volumeRejected))
                              ? " has-danger"
                              : ""
                          }>
                          <Label
                            htmlFor="volumeRejected"
                            className="control-label">
                            {this.state.addedProduct &&
                            this.state.addedProduct.kind !== "crops"
                              ? " Rejected Quantity"
                              : " Rejected Volume"}
                          </Label>
                          <InputGroup>
                            <Input
                              id="volumeRejected"
                              name="volumeRejected"
                              className="volumeRejected"
                              placeholder="Rejected Volume"
                              type="text"
                              value={
                                this.state.addedProduct &&
                                this.state.addedProduct.volumeRejected
                                  ? this.state.addedProduct.volumeRejected
                                  : "0"
                              }
                              onChange={this.handleChangeAddProduct}
                            />
                            <InputGroupText
                              className={
                                this.state.submittedModal &&
                                (!this.state.addedProduct ||
                                  !this.state.addedProduct.volumeRejected ||
                                  isNaN(this.state.addedProduct.volumeRejected))
                                  ? " has-danger"
                                  : ""
                              }>
                              {this.state.addedProduct &&
                              this.state.addedProduct.unit
                                ? this.state.addedProduct.unit
                                : "pc(s)."}
                            </InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="6">
                        <FormGroup
                        // className={
                        //   this.state.submittedModal &&
                        //   (!this.state.addedProduct ||
                        //     !this.state.addedProduct.volumeRestored ||
                        //     isNaN(this.state.addedProduct.volumeRestored))
                        //     ? " has-danger"
                        //     : ""
                        // }
                        >
                          <Label
                            htmlFor="volumeRestored"
                            className="control-label">
                            Restored Volume
                          </Label>
                          <InputGroup>
                            <Input
                              id="volumeRestored"
                              name="volumeRestored"
                              className="volumeRestored"
                              placeholder="Restored Volume"
                              type="text"
                              value={
                                this.state.addedProduct &&
                                this.state.addedProduct.volumeRestored
                                  ? this.state.addedProduct.volumeRestored
                                  : "0"
                              }
                              onChange={this.handleChangeAddProduct}
                            />
                            <InputGroupText>
                              {this.state.addedProduct &&
                              this.state.addedProduct.unit
                                ? this.state.addedProduct.unit
                                : "pc(s)."}
                            </InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      {/* <Col sm="12" md="6" lg="6">
                        <FormGroup>
                          <Label htmlFor="miscCost" className="control-label">
                            Additional Miscellaneous
                          </Label>
                          <InputGroup>
                            <InputGroupText>&#8369;</InputGroupText>
                            <Input
                              id="miscCost"
                              name="miscCost"
                              readOnly
                              className="miscCost"
                              placeholder="Misc Cost"
                              type="text"
                              value={
                                this.state.addedProduct &&
                                this.state.addedProduct.miscCost
                                  ? this.state.addedProduct.miscCost
                                  : 0
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col> */}
                      <Col sm="12" md="6" lg="6">
                        <FormGroup>
                          <Label htmlFor="costLost" className="control-label">
                            Loss Cost
                          </Label>
                          <InputGroup>
                            <InputGroupText>&#8369;</InputGroupText>
                            <Input
                              id="costLost"
                              name="costLost"
                              readOnly
                              className="costLost"
                              placeholder="Loss Cost"
                              type="text"
                              value={
                                this.state.addedProduct &&
                                this.state.addedProduct.costLost
                                  ? this.state.addedProduct.costLost
                                  : 0
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>
                </ModalBody>
                <ModalFooter
                  style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button color="secondary" onClick={this.toggleModal}>
                    Cancel
                  </Button>

                  <span></span>
                  <Button color="info" onClick={this.handleAddProduct}>
                    {"Add"}
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </>
      );
    }
  }
}
const capitalizeFirstLetter = (str) => {
  if (typeof str === "string" && str.length > 0) {
    return str.replace(/^./, str[0].toUpperCase());
  }
};
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  getProductCategoriesAllV2,
  addProductCategory,
  uploadPhoto,
  removePhoto,
  getProductById,
  getInventory,
  getProductStock,
  updateReject,
  getCostLogsByProduct,
  getRejectById,
  getPriceLogsByQuery,
  getPriceLogsByProduct,
})(EditReject);
